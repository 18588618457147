import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerCategoryDialogComponent} from '@management/customer/customer-category/customer-category-dialog.component';
import {DialogHostModule} from '@libs/ui/src';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';


@NgModule({
    declarations: [CustomerCategoryDialogComponent],
    imports: [
        CommonModule,
        DialogHostModule,
        FormsModule,
        TranslateModule,
        MatButtonModule
    ]
})
export class CustomerCategoryDialogModule {
}
