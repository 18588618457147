<omt-dialog-host [title]="title" (closeClicked)="closeModal()">
  <div dialog-content>
    <div class="form-group row m-b-15">
      <label class="col-form-label col-md-3">Name</label>
      <div class="col-md-9">
        <input type="text" class="form-control redesign m-b-5" [(ngModel)]="categoryModel.name" placeholder="Name" (keyup.enter)="save()"/>
        <small class="f-s-12 text-grey-darker">{{ 'MANAGEMENT.PH_NEW_CATEGORY' | translate }}</small>
      </div>
    </div>
  </div>

  <div dialog-footer>
    <button mat-raised-button color="primary" type="button" (click)="save()">{{ 'GLOBAL.SAVE' | translate }}</button>
  </div>
</omt-dialog-host>
